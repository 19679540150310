import ServicesDetails from '../ServicesDetails';
import ContactUsIcon from '../../Contactus/contacticon';

const CustomSoftwareDevelopmentService = () => {

  const title = "Custom Software Development Service";
  const details = "Unleash tailored innovation with our Custom Software Development Service. From dynamic Content Management to personalized CRM and seamless ERP solutions, we craft bespoke systems that elevate operations and drive growth. Whether optimizing workflows with Operation Management or powering online success with E-commerce platforms, our expertise transforms your unique vision into interactive reality.";

  const deliverables = [
    { title: "Content Management System (CMS)", link: "/cms-details", content: "Empower your content strategy with a personalized CMS. Our solutions streamline content creation, management, and distribution, ensuring a dynamic and engaging online presence." },
    { title: "Customer Relationship Management System (CRMS)", link: "/crms-details", content: "Forge lasting connections with your customers through our CRMS solutions. From lead management to customer insights, we customize systems that elevate your customer relationships." },
    { title: "Enterprise Resource Planning Software", link: "/erp-software-details", content: "Optimize resource utilization and enhance decision-making with our ERP software. Tailored to your business needs, our solutions seamlessly integrate processes across departments for holistic efficiency." },
    { title: "Operation Management System", link: "/operation-management-details", content: "Efficiently orchestrate your operations with our bespoke Operation Management System. From workflow automation to real-time monitoring, we optimize processes for peak performance." },
    { title: "E-commerce Software", link: "/ecommerce-software-details", content: "Catapult your online presence with customized E-commerce Software. We build scalable and user-friendly platforms that enhance the customer shopping experience and drive online business growth." },
    { title: "Enterprise Software Solution", link: "/enterprise-software-details", content: "Empower your organization with our holistic Enterprise Software Solutions. Tailored to your unique requirements, we create integrated and scalable solutions that elevate every aspect of your business operations." },
  ];

  const technologies = [
    { src: "https://example.com/image1.jpg", caption: "Image 1" },
    { src: "https://example.com/image2.jpg", caption: "Image 2" },
    // Add more technologies as needed
  ];

  const whyApnito = [
    "Our team offers tailored solutions to meet your unique business needs",
    "We expertise in developing custom softwares for various industries and business domains",
    "End-to-end software development services from ideation to deployment by a team of experts",
    "We commit agile development methodology to ensure timely delivery and flexibility",
    "Robust and scalable software architecture to accommodate future growth",
    "Experienced team of software developers, designers, and project managers",
    "We integrate with third-party systems and APIs to maximize functionality",
    "Ongoing support and maintenance by us to keep your software up-to-date and running smoothly",
    "We offer competitive pricing and cost-effective solutions to maximize ROI",
    "Our team ensures commitment to client satisfaction and open communication throughout the development process",
  ];

  return (
    <div>
      <ServicesDetails
        title={title}
        details={details}
        deliverables={deliverables}
        technologies={technologies}
        whyApnito={whyApnito}
      />
      <ContactUsIcon/>
    </div>
  );
};

export default CustomSoftwareDevelopmentService;
