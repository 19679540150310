// ClientLogoSlider.js
import React from 'react';

const ClientLogoSlider = ({ logos }) => {
  return (
    <div className="client-logo-slider">
      <div>
        <h1 className="trusted-by-header">Successful Businesses unlocking their full potential with Apnito
</h1>
        <div className="client-logo-container">
          {logos.map((logo, index) => (
            <img
              key={index}
              src={logo}
              alt={`Client Logo ${index + 1}`}
              className="client-logo"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClientLogoSlider;
