import React from "react";
import "./About.css";

const AboutUs = () => {
  return (
    <div className="about-us" id='about'>
      <br></br><br></br>
      {/* <h1>!! About Us !!</h1> */}
      {/* Add the centered image */}
      <div className="centered-image">
        <img
          src="https://t3.ftcdn.net/jpg/05/06/32/62/360_F_506326245_2GtSGEjKLDtpHS0FSkEBs4gV34DmTtS5.jpg" // Replace with the actual image path
          alt="Centered Image"
          className="image" // Optional: Apply additional styling if needed
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </div>
      <p>
        Apnito is a consulting firm that helps businesses and individuals to achieve their goals through cutting-edge solutions. We understand that every business and individual has unique needs and challenges, which is why we are committed to provide our clients with the best possible solutions to help them achieve their goals.
      </p>
      <p>
        Our Services include Transcription & Translation, Data Scraping, Web Development, App Development, Bot Development, Cloud & DevOps, Data Management & Analytics, Process Automation & Custom Software Development.
      </p>
      <p>
        Our Career Supports include Profile Reviewer, Mentorship Program, Mock Interview and Career Guidance & Counselling.
      </p>
      <p>
        Whether you need a Software Solution or want Career Supports we can do that very efficiently and affordably. With a team of highly skilled professionals and tailored solutions, we strive to deliver innovative and effective results. Contact us to see how we can help you elevate you to the next level.
      </p>
    </div>
  );
};

export default AboutUs;
