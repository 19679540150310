import React from 'react';
import { useNavigate } from 'react-router-dom';
import CareerSupportsDetails1 from '../CareerSupportsDetails1';
import ContactUsIcon from '../../Contactus/contacticon';
import interviewDetails from './interview_details.json';

const MockInterview = () => {
  const navigate = useNavigate();

  const updateImagePaths = (details) => {
    const updatedDetails = {};
    
    Object.keys(details).forEach((key) => {
      updatedDetails[key] = details[key].map((item) => ({
        ...item,
        image: require(`../../../image/CareerSupports/${item.image}`)
      }));
    });
    
    return updatedDetails;
  };

  const title = "Mock Interview";
  const details = "At Apnito, we understand the importance of being well-prepared for each stage of the interview process. Our Mock Interview Program is meticulously designed to help you excel in all technical and non-technical rounds. We offer specialized mock interviews to build your confidence and refine your skills, ensuring you're ready to impress any potential employer. Join Apnito’s Mock Interview Program today and take the next step toward acing every round of your interviews with confidence and expertise.";

  const deliverables = updateImagePaths(interviewDetails);

  const technologies = [
    { src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIJj6T3Pm3wSOAznARupQIb1_H-BZvpdSoxw&usqp=CAU", caption: "Image 1" },
    { src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIJj6T3Pm3wSOAznARupQIb1_H-BZvpdSoxw&usqp=CAU", caption: "Image 2" },
  ];

  const whyApnito = [
    "Experience realistic mock interviews designed to mirror actual interview scenarios, enhancing your preparedness",
    "Conducted by experienced professionals who provide in-depth feedback and insights",
    "Comprehensive preparation for all technical and non-technical rounds, including coding, design, and soft skills",
    "Specific focus on different interview rounds, ensuring you are well-prepared for every aspect of the interview process",
    "Boost your confidence with practice sessions and constructive critiques to refine your performance"
  ];

  const handleProfileSelect = (profile) => {
    navigate(`/career-supports/mock-interview?profile=${profile}`);
  };

  return (
    <div>
      <CareerSupportsDetails1
        title={title}
        details={details}
        deliverables={deliverables}
        technologies={technologies}
        whyApnito={whyApnito}
        onProfileSelect={handleProfileSelect} // Pass the callback function
      />
      <ContactUsIcon />
    </div>
  );
};

export default MockInterview;
