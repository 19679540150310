import React, { useState, useEffect } from 'react';
import './NavBars.css';

const NavBars = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState('/');
  const [isTransparent, setIsTransparent] = useState(true);

  useEffect(() => {
    setCurrentPath(window.location.pathname);

    const handleScroll = () => {
      const scrollY = window.scrollY;
      const isTransparent = scrollY < window.innerHeight*0.5;
      setIsTransparent(isTransparent);
    };

    const handlePageChange = () => {
      setCurrentPath(window.location.pathname);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('popstate', handlePageChange);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('popstate', handlePageChange);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuItemClick = () => {
    setMenuOpen(false);
  };

  return (
    <nav className={`navbar ${isTransparent && currentPath === '/' ? 'transparent-bg' : 'scrolled-bg'}`}>
      <div className="navbar-left">
        <a href='/'>
          <img className="logo" src={require('./assets/image.webp')} alt="company logo" />
        </a>
      </div>
      <div className={`navbar-right ${menuOpen ? "active" : ""}`}>
        <a href="/" onClick={handleMenuItemClick}>Home</a>
        <a href="/#about" onClick={handleMenuItemClick}>About Us</a>
        <a href="/services" onClick={handleMenuItemClick}>Services</a>
        <a href="/career-supports" onClick={handleMenuItemClick}>Career Supports</a>
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        <i className={`fas fa-${menuOpen ? "times" : "bars"}`}></i>
      </div>
    </nav>
  );
};

export default NavBars;
