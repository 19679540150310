import ServicesDetails from '../ServicesDetails';
import ContactUsIcon from '../../Contactus/contacticon';

const TranscriptionTranslationService = () => {

  const title = "Transcription & Translation Service";
  const details = "Apnito offers professional Transcription & Translation services to help individuals and businesses effectively manage their audio and document needs. Whether you require accurate audio transcriptions or precise document translations, we provide tailored solutions to meet your requirements. Choose Apnito for your Transcription & Translation needs and experience seamless and accurate solutions that elevate your communication efforts.";

  const deliverables = [
    { title: "Audio Transcription", link: "/audio-transcription-details", content: "Our Audio Transcription service ensures accurate conversion of spoken content into written text. With Apnito's Audio Transcription service, you can efficiently convert your audio recordings into editable text for various purposes." },
    { title: "Document Translation", link: "/document-translation-details", content: "Our Document Translation service ensures accurate and culturally appropriate translations of written content. With Apnito's Document Translation service, you can expand your global reach and effectively communicate across language barriers." }
  ];

  const technologies = [
    { src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIJj6T3Pm3wSOAznARupQIb1_H-BZvpdSoxw&usqp=CAU", caption: "Image 1" },
    { src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIJj6T3Pm3wSOAznARupQIb1_H-BZvpdSoxw&usqp=CAU", caption: "Image 2" },
  ];

  const whyApnito = [
    "Delivering precise and dependable transcription and translation solutions to meet your needs", 
    "Tailoring our services to accommodate your specific requirements and preferences", 
    "Providing fast and efficient processing to meet your deadlines and timelines", 
    "Ensuring strict adherence to confidentiality and security protocols to safeguard your data",
    "Collaborating with skilled professionals and linguists to deliver high-quality results that exceed expectations"
  ];

  return (
    <div>
      <ServicesDetails
        title={title}
        details={details}
        deliverables={deliverables}
        technologies={technologies}
        whyApnito={whyApnito}
      />
      <ContactUsIcon/>
    </div>
  );
};

export default TranscriptionTranslationService;
