import React from "react";
import './contacticon.css'
import logo from '../images/3dgifmaker91900.gif'

const ContactUsIcon = () => {
    return(
        <div className='contact-logo'>
            <a href='/#contact'>
                <img src={logo} alt="Contact Logo"/>
                <span className="hover-text">Contact Us</span>
            </a>
        </div>
    )
}

export default ContactUsIcon;