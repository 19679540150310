import CareerSupportsDetails from '../CareerSupportsDetails';
import ContactUsIcon from '../../Contactus/contacticon';
import career_doubts from '../../../image/CareerSupports/Career_Doubts.jpeg';
import profile_based_guidance from '../../../image/CareerSupports/Profile_Based_Guidance.jpeg';

const CareerGuidanceCounselling = () => {

  const title = "Career Guidance & Counselling";
  const details = "Embarking on a career journey can be daunting, but with Apnito’s Career Guidance & Counseling, you'll gain clarity and confidence to pursue your professional aspirations. Whether you're just starting out, facing challenges in your chosen field, or grappling with doubts, we provide personalized guidance and support to help you make informed decisions and achieve success. Join Apnito's Career Guidance & Counseling services today and embark on a fulfilling and successful career journey.";

  const deliverables = [
    { title: "General Career Guidance", 
      link: "/audio-transcription-details", 
      content: "Our General Career Guidance service is tailored for individuals unsure of where to begin or what career options are available to them. With Apnito, you'll gain clarity on your career direction and the steps needed to achieve your goals.", 
      duration:["30 Mins", "60 Mins"], 
      price: ["₹ 249", "₹ 399"],
      actualPrice: ["₹ 299", "₹ 599"], 
      recommended: ["", "RECOMMENDED"],
      image: profile_based_guidance},
    { title: "Profile Based Guidance", 
      link: "/document-translation-details", 
      content: "If you're already preparing for specific roles or facing challenges in your chosen field, our Profile-Based Guidance service can help you overcome obstacles and achieve success. With Apnito's expertise and support, you can overcome hurdles and excel in your chosen career path.", 
      duration:["30 Mins", "60 Mins"], 
      price: ["₹ 199", "₹ 299"],
      actualPrice: ["₹ 249", "₹ 499"], 
      recommended: ["", "RECOMMENDED"],
      image: profile_based_guidance},
    { title: "Career Doubts", 
      link: "/document-translation-details", 
      content: "If you're grappling with doubts or uncertainties about your career path, our Career Doubts service provides a platform to seek clarity and guidance. With Apnito, you can address your doubts and gain confidence in your career decisions.", 
      duration:["30 Mins", "60 Mins"], 
      price: ["₹ 249", "₹ 399"],
      actualPrice: ["₹ 299", "₹ 599"],
      recommended: ["", "RECOMMENDED"],
      image: career_doubts}
 ];

  const technologies = [
    { src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIJj6T3Pm3wSOAznARupQIb1_H-BZvpdSoxw&usqp=CAU", caption: "Image 1" },
    { src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIJj6T3Pm3wSOAznARupQIb1_H-BZvpdSoxw&usqp=CAU", caption: "Image 2" },
  ];

  const whyApnito = [
  "Tailored advice and support to meet your unique career needs and aspirations", 
  "Access to experienced career counselors and industry professionals", 
  "Assistance at every stage of your career journey, from exploration to advancement", 
  "A safe and supportive environment to discuss your concerns and seek guidance",
  "Gain clarity on your career path and the confidence to pursue your professional goals with conviction"
  ];

  return (
    <div>
      <CareerSupportsDetails
        title={title}
        details={details}
        deliverables={deliverables}
        technologies={technologies}
        whyApnito={whyApnito}
      />
      <ContactUsIcon/>
    </div>
  );
};

export default CareerGuidanceCounselling;
