import ServicesDetails from '../ServicesDetails';
import ContactUsIcon from '../../Contactus/contacticon';

const BotDevelopmentService = () => {
  const title = "Bot Development Service";
  const details = "Transform your interactions with our Bot Development Service suite! From seamless Voice Bots to intuitive Menu and Button-based Chatbots, we offer a range of solutions tailored to your needs. Dive into the future with Rule-Based, Keyword Recognition, and Hybrid Chatbots, or elevate engagement with the power of AI and Generative AI Chatbots. Explore the possibilities and let us craft a Custom Chatbot that amplifies your unique vision. Your conversational journey starts here!";

  const deliverables = [
    { title: "Voice Bots", link: "/voice-bots-details", content: "Engage users effortlessly with our Voice Bots. Transform spoken words into seamless interactions, providing a hands-free and intuitive experience for your audience." },
    { title: "Menu or Button Based Chatbots", link: "/menu-button-chatbots-details", content: "Simplify user engagement with Menu or Button-based Chatbots. Navigate through information effortlessly, allowing users to access relevant content with just a click or tap." },
    { title: "Rule Based Chatbots", link: "/rule-based-chatbots-details", content: "Immerse your audience in guided conversations with Rule-Based Chatbots. Define specific rules to ensure precise and effective interactions, providing a structured and tailored user experience." },
    { title: "Keyword Recognition Based Chatbots", link: "/keyword-recognition-chatbots-details", content: "Harness the power of language with Keyword Recognition Based Chatbots. Enable natural conversations by identifying key phrases, delivering context-aware responses for a more intuitive dialogue." },
    { title: "Hybrid Chatbots", link: "/hybrid-chatbots-details", content: "Experience the best of both worlds with our Hybrid Chatbots. Seamlessly blend rule-based approaches with AI capabilities, offering a versatile solution that adapts to various user needs." },
    { title: "AI Powered Chatbots", link: "/ai-powered-chatbots-details", content: "Elevate your conversations with the intelligence of AI. Our AI Powered Chatbots leverage advanced algorithms to understand user intent, ensuring dynamic and personalized interactions." },
    { title: "Generative AI Chatbots", link: "/generative-ai-chatbots-details", content: "Step into the future of chat experiences with Generative AI Chatbots. Powered by cutting-edge technology, these bots generate contextually relevant responses, providing a truly conversational and human-like touch." },
    { title: "Custom Chatbots", link: "/custom-chatbots-details", content: "Tailor your conversational experience with Custom Chatbots. Crafted to align seamlessly with your unique requirements, these bots are designed to reflect your brand personality and cater to specific user needs." },
  ];

  const technologies = [
    { src: "bot-image1.jpg", caption: "Bot Image 1" },
    { src: "bot-image2.jpg", caption: "Bot Image 2" },
    // Add more technologies as needed
  ];

  const whyApnito = [
    "Comprehensive bot development services to automate business processes & enhance customer engagement",
    "Experienced team skilled in creating chatbots, monitoring bots, social bots, shopping bots, and more",
    "Custom bot development solutions tailored to meet the specific needs and objectives of each client",
    "Integration with popular messaging platforms, social media channels, and eCommerce platforms",
    "Use of natural language processing (NLP) and machine learning (ML) technologies for intelligent interactions",
    "Ongoing support & maintenance services to ensure the smooth functioning & optimal performance of bots",
    "Cost-effective pricing plans & flexible engagement models to accommodate businesses of all sizes & budgets",
    "Commitment to innovation, quality, and customer satisfaction",
  ];

  return (
    <div>
      <ServicesDetails
        title={title}
        details={details}
        deliverables={deliverables}
        technologies={technologies}
        whyApnito={whyApnito}
      />
      <ContactUsIcon/>
    </div>
  );
};

export default BotDevelopmentService;
