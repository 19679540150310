
import { Route,  Routes } from 'react-router-dom';
import Home from './Components/Home/Home';
import './App.css';
import Services from './Components/Services/Services';
import CareerSupports from './Components/CarrierSupports/CareerSupports'
import Careers from './Components/Careers/Careers';
import DataScraping from './Components/Services/ServicesDetails/DataScraping';
import AppDevelopment from './Components/Services/ServicesDetails/AppDevelopment';
import BotDevelopment from './Components/Services/ServicesDetails/BotDevelopment';
import CloudDevOps from './Components/Services/ServicesDetails/CloudDevOps';
import CustomSoftwareDevelopment from './Components/Services/ServicesDetails/CustomSoftwareDevelopment';
import DataManagementAnalytics from './Components/Services/ServicesDetails/DataManagementAnalytics';
import ProcessAutomation from './Components/Services/ServicesDetails/ProcessAutomation';
import WebDevelopment from './Components/Services/ServicesDetails/WebDevelopment';
import TranscriptionTranslation from './Components/Services/ServicesDetails/TranscriptionTranslation';
import MentorshipProgram from './Components/CarrierSupports/CareerSupportsDetails/MentorshipProgram';
import MockInterview from './Components/CarrierSupports/CareerSupportsDetails/MockInterview';
import CareerGuidanceCounselling from './Components/CarrierSupports/CareerSupportsDetails/CareerGuidanceCounselling';
import ProfileReviewer from './Components/CarrierSupports/CareerSupportsDetails/ProfileReviewer';
import Training from './Components/CarrierSupports/CareerSupportsDetails/Training';

function App() {
  return (
    <div >
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/services/data-scraping-service" element={<DataScraping/>} />
        <Route path="/services/app-development-service" element={<AppDevelopment/>} />
        <Route path="/services/web-development-service" element={<WebDevelopment/>} />
        <Route path="/services/bot-development-service" element={<BotDevelopment/>} />
        <Route path="/services/cloud-devops-service" element={<CloudDevOps/>} />
        <Route path="/services/custom-software-development-service" element={<CustomSoftwareDevelopment/>} />
        <Route path="/services/data-management-analytics-service" element={<DataManagementAnalytics/>} />
        <Route path="/services/process-automation-service" element={<ProcessAutomation/>} />
        <Route path="/services/transcription-translation-service" element={<TranscriptionTranslation/>} />
        <Route path="/career-supports/mentorship-program" element={<MentorshipProgram/>} />
        <Route path="/career-supports/mock-interview" element={<MockInterview/>} />
        <Route path="/career-supports/career-guidance-counselling" element={<CareerGuidanceCounselling/>} />
        <Route path="/career-supports/profile-reviewer" element={<ProfileReviewer/>} />
        <Route path="/career-supports/training" element={<Training/>} />
        <Route path="/careers" element={<Careers/>} />
        <Route path="/career-supports" element={<CareerSupports/>} />
      </Routes>
    </div>
  );
}

export default App;

