import React from 'react';
import './Careers.css';
import JobRole from './JobRole';
import NavBars from '../Home/NavBars/NavBars';
import Footer from '../Home/Footer/Footer';
import ContactUsIcon from '../Contactus/contacticon';

function Careers() {
  return (
    <div>
    <NavBars />
    <br></br><br></br><br></br><br></br>
    <div className="Careers">
      <center><h1>Join Our Team</h1></center>
      <div className="JobRoles">
        <JobRole
          title="Software Engineer"
          summary="Currently don't have any open positions, we encourage you to keep an eye on our career page for any future opportunities"
          detailsUrl="/software-engineer"
        />
        <JobRole
          title="Software Engineer"
          summary="Our team is always growing and we would love to hear from talented individuals like you. Stay tuned for updates and we look forward to potentially working with you in the future"
          detailsUrl="/software-engineer"
        />
        {/* <JobRole
          title="UI/UX Designer"
          summary="We're looking for a UI/UX designer with experience in Figma and Adobe Creative Suite. Click 'Read Details' to learn more."
          detailsUrl="/ui-ux-designer"
        /> */}
        {/* Add more job roles here */}
      </div>
    </div>
    <Footer/>
    <ContactUsIcon/>
    </div>
  );
}

export default Careers;
