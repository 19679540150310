import ServicesDetails from '../ServicesDetails';
import ContactUsIcon from '../../Contactus/contacticon';

const WebDevelopmentService = () => {
  const title = "Web Development Service";
  const details = "Craft your online presence with our Web Development Service! Imagine stunning designs that make your website pop (UI/UX Designing), pages that work effortlessly on any device (Frontend Web Development), and a powerful engine behind it all (Backend Web Development). For the full package, our Full Stack Web Development brings it all together. Let's build a website that's not just seen but experienced—where creativity and interactivity meet!";

  const deliverables = [
    { title: "UI/UX Designing", link: "/ui-ux-designing-details", content: "Elevate your digital presence with our UI/UX Designing. We craft visually stunning and intuitive interfaces, ensuring a delightful user journey that leaves a lasting impression." },
    { title: "Frontend Web Development", link: "/frontend-web-development-details", content: "Bring your designs to life with our Frontend Web Development. We create dynamic, responsive, and user-friendly interfaces that captivate users across various devices and platforms." },
    { title: "Backend Web Development", link: "/backend-web-development-details", content: "Power your web applications with robust Backend Web Development. We build scalable and efficient server-side architectures, ensuring seamless functionality and data management behind the scenes." },
    { title: "Full Stack Web Development", link: "/full-stack-web-development-details", content: "Experience the synergy of Frontend and Backend with our Full Stack Web Development. From user interfaces to server-side logic, we deliver end-to-end solutions that embody the perfect balance of form and function." },
  ];

  const technologies = [
    { src: "web-image1.jpg", caption: "Web Image 1" },
    { src: "web-image2.jpg", caption: "Web Image 2" },
    // Add more technologies as needed
  ];

  const whyApnito = [
    "Professional website design and development services for businesses of all sizes",
    "Customized website solutions tailored to meet the unique needs and goals of each client",
    "Wide range of website types and styles including eCommerce, business, personal, blog, portfolio, and more",
    "Expertise in popular web development technologies and platforms like WordPress, Shopify, Magento, and more",
    "Responsive and mobile-friendly design to ensure your website looks great on any device",
    "User-friendly interfaces to enhance the user experience and improve engagement",
    "Ongoing maintenance and support to keep your website up-to-date and secure",
    "Competitive pricing and flexible packages to fit any budget and project scope",
  ];

  return (
    <div>
      <ServicesDetails
        title={title}
        details={details}
        deliverables={deliverables}
        technologies={technologies}
        whyApnito={whyApnito}
      />
      <ContactUsIcon/>
    </div>
  );
};

export default WebDevelopmentService;
