import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Card, Row, Col } from 'react-bootstrap';
import './ServicesDetails.css';
import NavBars from './../Home/NavBars/NavBars';
import Footer from './../Home/Footer/Footer';

const ServicesDetailsComponent = ({
  title,
  details,
  deliverables,
  technologies,
  whyApnito,
}) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on page load
  }, []);

  return (
    <div>
      <NavBars />
      <div className="service-detail">
        <br /><br /><br />
        <div className="header">
          <center>
            <h2 className="service-title">
              <span className="header-text">{title}</span>
            </h2>
          </center>
        </div>
        <p className="service-details">{details}</p>
        <Tabs activeKey={activeTab} onSelect={(k) => handleTabClick(k)} id="tabs">
          <Tab eventKey={1} title={<span className="tab-header-1">Our Deliverables</span>}>
            {deliverables.map((deliverable, index) => (
              <Card key={index} className="my-3">
                <Card.Body>
                  <Row>
                    <Col>
                      <Card.Title className="card-title">{deliverable.title}</Card.Title>
                    </Col>
                    {/* <Col xs="auto">
                      <Button href={deliverable.link}>Learn More</Button>
                    </Col> */}
                  </Row>
                  <Card.Text className='card-content'>{deliverable.content}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </Tab>
          {/* <Tab eventKey={2} title="Our Technologies">
            <Carousel pause="false" interval={3000}>
              {technologies.map((technology, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={technology.src}
                    alt={`Technology ${index + 1}`}
                  />
                  <Carousel.Caption>
                    <h3>{technology.caption}</h3>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
          </Tab> */}
          <Tab eventKey={3} title={<span className='tab-header-2'>Why Apnito?</span>}>
            <div className="why-apnito">
              <ul className="why-apnito-list">
                {whyApnito.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>
          </Tab>
        </Tabs>
      </div>
      <br/>
      <Footer />
    </div>
  );
};

export default ServicesDetailsComponent;
