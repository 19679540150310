import CareerSupportsDetails from '../CareerSupportsDetails';
import ContactUsIcon from '../../Contactus/contacticon';
import interview_tips from '../../../image/CareerSupports/Interview_preparation_tips.jpeg';
import projec_based_learning from '../../../image/CareerSupports/Project_Based_Learning.jpeg';
import project_based_guidance from '../../../image/CareerSupports/Profile_Based_Guidance.jpeg';

const MentorshipProgram = () => {

  const title = "Mentorship Program";
  const details = "At Apnito, we believe in empowering individuals to reach their fullest potential through our comprehensive Mentorship Program. Designed to support your career growth and skill development, our program offers personalized guidance across key areas: Interview Preparation & Tips, Project Guidance, and Project-Based Learning. Join Apnito’s Mentorship Program today and take the next step toward professional excellence and career success.";

  const deliverables = [
    { title: "Interview Prepration & Tips", 
      link: "/audio-transcription-details", 
      content: "Navigating the interview process can be daunting, but with Interview Preparation & Tips service, you’ll walk in with confidence. With Apnito, you’ll be fully prepared to impress potential employers and secure your desired position.", 
      duration: ["30 Mins", "60 Mins"], 
      price: ["₹ 249", "₹ 399"],
      actualPrice: ["₹ 299", "₹ 599"],  
      recommended: [""],
      image: interview_tips},
    { title: "Project Guidance", 
      link: "/document-translation-details", 
      content: "Are you working on a project but finding it challenging to complete? Project Guidance service is here to help you navigate through obstacles and achieve your project goals. With Apnito, you can confidently tackle and complete your projects with expert support.", 
      duration:["Duration As Required"], 
      price: ["₹ Custom"],
      recommended: [""],
      image: project_based_guidance},
    { title: "Project Based Learning", 
      link: "/document-translation-details", 
      content: "If you’re looking to develop new skills but aren’t sure where to start, Project Based Learning service is perfect for you. We guide you through practical projects that build your expertise. With Apnito, you’ll gain the skills and confidence needed to advance your career through practical, hands-on learning.", 
      duration:["Duration As Required"], 
      price: ["₹ Custom"],
      recommended: [""],
      image: projec_based_learning}
 ];

  const technologies = [
    { src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIJj6T3Pm3wSOAznARupQIb1_H-BZvpdSoxw&usqp=CAU", caption: "Image 1" },
    { src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIJj6T3Pm3wSOAznARupQIb1_H-BZvpdSoxw&usqp=CAU", caption: "Image 2" },
  ];

  const whyApnito = [
    "Tailored guidance and support from industry experts to meet your specific learning needs and career goals",
    "Assistance in every aspect of your project and skill development journey, from initiation to completion",
    "Focused on practical, hands-on learning to ensure you gain relevant and applicable skills",
    "Regular progress reviews and constructive feedback to keep you on track",
    "Access to a wide range of tools, materials, and resources to support your learning and project execution"
  ];

  return (
    <div>
      <CareerSupportsDetails
        title={title}
        details={details}
        deliverables={deliverables}
        technologies={technologies}
        whyApnito={whyApnito}
      />
      <ContactUsIcon/>
    </div>
  );
};

export default MentorshipProgram;
