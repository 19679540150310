import ServicesDetails from '../ServicesDetails'; // Adjust the path as needed
import ContactUsIcon from '../../Contactus/contacticon';

const CloudDeveOpsService = () => {
  // Define the content specific to your service
  const title = "Cloud & DevOps Service";
  const details = "Embark on a digital transformation journey with our Cloud & DevOps Services. Elevate your operations to the cloud seamlessly with solutions spanning architecture, migration, and multi-cloud platforms. Supercharge software delivery through automated DevOps pipelines, optimize costs with precision, and fortify your digital landscape with robust security and disaster management. Our interactive approach ensures your business thrives in the dynamic world of cloud computing and DevOps innovation. Explore, optimize, and secure your digital future with us!";

  const deliverables = [
    { title: "Consulting", link: "/consulting-details", content: "Navigate the world of DevOps and cloud platforms with confidence. Our consulting services offer insights, strategies, and hands-on assistance across AWS, Azure, and GCP. Explore the right IAAS, PAAS, and SAAS services for your unique requirements." },
    { title: "Cloud Solutions", link: "/cloud-solutions-details", content: "Unlock the full potential of the cloud with our tailored solutions. From architecting cloud landscapes to seamless migration across AWS, Azure, and GCP, we enhance app security, set up multi-cloud platforms, and implement failover strategies. Let's elevate your digital journey to new heights." },
    { title: "DevOps Solutions", link: "/devops-solutions-details", content: "Supercharge your software's journey from conception to deployment. Our DevOps solutions accelerate time-to-market with automated CI/CT/CD pipelines. Maximize your IT investment with battle-tested DevOps services, ensuring efficiency at every stage of development." },
    { title: "Cost/Cloud Optimization", link: "/cloud-optimization-details", content: "Optimize your cloud investment with precision. We assess your business needs, recommending optimal cloud migration strategies, be it for cloud-native applications or hybrid cloud transformations. Reduce IT overheads and management costs through dynamic provisioning." },
    { title: "Cloud Migration", link: "/cloud-migration-details", content: "Seamlessly transition to the cloud with our expert Cloud Migration services. We assess your needs, providing optimal strategies for migrating specific applications or executing a smooth hybrid cloud transformation. Embrace a future of flexibility and scalability tailored to your unique requirements." },
    { title: "Infrastructure & Automation", link: "/infrastructure-automation-details", content: "Unleash the power of automation for a more efficient IT landscape. Replace manual processes with dynamic infra provisioning, reducing time-consuming tasks. Embrace a pay-for-use billing model, ensuring high reduction in costs without compromising performance." },
    { title: "Virtualization & App Containerization", link: "/virtualization-containerization-details", content: "Deliver applications swiftly and efficiently with our virtualization and containerization solutions. Increase app-to-machine ratio, distribute applications seamlessly across hosts, and achieve high reliability and consistency, regardless of your deployment environment." },
    { title: "Data/Database as a Service", link: "/data-database-service-details", content: "Transform your data landscape with our expertise. From data scraping to migration and transformation, we ensure your data is structured for optimal usability. Embrace cost-effective solutions for data/database migration and reduce complexities." },
    { title: "Auditing, Monitoring & Logging", link: "/auditing-monitoring-logging-details", content: "Ensure organizational resilience with our automated auditing, monitoring, and logging solutions. Collect critical data, resolve incidents, and uphold performance with organization-wide policies, creating a robust system for a secure and efficient operation." },
    { title: "Security Compliance & Disasters Management", link: "/security-compliance-disasters-management-details", content: "Safeguard your digital assets with our security and disaster management solutions. From compliance and failover strategies to incident resolution, we've got you covered. Partner with us for a secure and resilient digital future." },
  ];

  const technologies = [
    { src: "image1.jpg", caption: "Caption 1" },
    { src: "image2.jpg", caption: "Caption 2" },
    // Add more technologies as needed
  ];

  const whyApnito = [
    "Our team of experts will work with you to develop a cloud solution tailored to your specific business needs",
    "We'll analyze your current cloud usage and identify opportunities for optimization to reduce costs and maximize efficiency",
    "Our ongoing monitoring and optimization services ensure that your cloud infrastructure remains optimized over time, saving you money and minimizing downtime",
    "We'll identify potential issues before they become problems, and provide proactive support to keep your business running smoothly",
    "Our cloud solutions are designed to be easily scalable as your business grows and evolves",
    "We can help you seamlessly migrate your existing infrastructure to the cloud, minimizing disruption to your business operations",
    "We pride ourselves on building long-term relationships with our clients, and are committed to provide the highest level of service and support"
  ];

  return (
    <div>
      <ServicesDetails
        title={title}
        details={details}
        deliverables={deliverables}
        technologies={technologies}
        whyApnito={whyApnito}
      />
      <ContactUsIcon/>
    </div>
  );
};

export default CloudDeveOpsService;