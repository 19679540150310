import React, { useRef, useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import Designer from "./Designer.png";
import "./ContactForm.css";

const ContactForm = () => {
  const form = useRef();
  const [isSending, setIsSending] = useState(false);

  const resetForm = () => {
    form.current.reset();
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSending(true);

    emailjs
      .sendForm(
        "service_nii01p5",
        "template_y7emb16",
        form.current,
        "5cqBYCrC0qpiQONyV"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          resetForm();
          alert("We successfully recieved your message, Our team will reach out to you for further process.");
        },
        (error) => {
          console.log(error.text);
          alert('Message not sent due to some technical issue, Please directly reach out to us at contact@apnito.in');
        }
      )
      .finally(() => {
        setIsSending(false);
      });
  };

  return (
    <StyledContactForm>
      <form ref={form} onSubmit={sendEmail}>
        <label>Name</label>
        <input type="text" name="user_name" required/>
        <label>Email</label>
        <input type="email" name="user_email" required/>
        <label>Phone Number</label>
        <input type="tel" name="user_number" required/>
        <label>Message</label>
        <textarea name="message" required/>
        {isSending ? (
          <button disabled>
            <i className="fa fa-spinner fa-spin" /> Sending...
          </button>
        ) : (
          <input type="submit" value="Send" />
        )}
      </form>
    </StyledContactForm>
  );
};

const ContactContainer = () => {
  useEffect(() => {
    if (window.location.hash === "#contact") {
      const contactSection = document.getElementById("contact");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <StyledContactContainer id="contact">
      <div className="heading">
        <br></br><br></br><br></br>
        {/* <h1>!! Contact Us !!</h1> */}
        <h2>Unlocking Your Potential Through Our Services and Supports</h2>
      </div>
      <div className="content">
        <ContactForm />
        <div className="illustration">
          <img src={Designer} alt="Illustration" />
        </div>
      </div>
    </StyledContactContainer>
  );
};

export default ContactContainer;

// Styles
const StyledContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;

  .heading {
    text-align: center;
    margin-bottom: 2rem;

    h2 {
      font-size: 2rem;
      font-family: 'Garamond';

      @media (max-width: 768px) {
        /* Change the font size for mobile screens */
        font-size: 1.5rem;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;

    .illustration {
      flex: 1;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
`;

// Styles
const StyledContactForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  min-height: 50vh;
  max-width: 800px;
  margin: 1 auto;

  .illustration {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10rem;

    svg {
      width: 80%;
      max-width: 500px;
      height: auto;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  form {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    margin-right: 4rem;
    font-family: 'Garamond';

    input {
      width: 100%;
      height: 60px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    label {
      margin-top: 1rem;
    }

    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: #3d52a0;
      color: white;
      border: none;
      font-size: 25px;
    }
  }
`;
