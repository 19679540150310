import ServicesDetails from '../ServicesDetails';
import ContactUsIcon from '../../Contactus/contacticon';

const AppDevelopmentService = () => {
  const title = "App Development Service";
  const details = "Explore endless possibilities with our comprehensive App Development Service! Whether you're aiming for Android users, Apple fans, or a blend of both with hybrid solutions, we've got you covered. Boost your brand with custom-built apps that combine the latest tech, user-friendly design, and compatibility across platforms. Let's turn your ideas into interactive, user-friendly realities because your digital success story starts here.";

  const deliverables = [
    { title: "Android App Development", link: "/android-app-development-details", content: "Embark on a digital journey with our Android App Development Service, where innovation meets seamless user experiences. Elevate your brand with custom-built Android apps, blending cutting-edge technology with intuitive design. Let's turn your ideas into interactive, user-centric realities." },
    { title: "iOS App Development", link: "/ios-app-development-details", content: "Unlock the power of iOS with our iOS App Development Service, where creativity meets functionality. Elevate your brand on the Apple ecosystem with custom apps that captivate and engage users. From sleek design to robust performance, we turn your vision into a seamless iOS experience." },
    { title: "Hybrid (Android + iOS) App Development", link: "/hybrid-app-development-details", content: "Experience the best of both worlds with our Hybrid App Development Service! Seamlessly bridging Android and iOS platforms, we craft versatile apps that redefine user engagement." },
  ];

  const technologies = [
    { src: "app-image1.jpg", caption: "App Image 1" },
    { src: "app-image2.jpg", caption: "App Image 2" },
    // Add more technologies as needed
  ];

  const whyApnito = [
    "Customized mobile apps that cater to your unique business needs & requirements",
    "Help with new app development or revamp existing ones for improved user-friendliness and functionality",
    "Development for both Android & iOS platforms, ensuring compatibility with all types of devices",
    "Design apps that are easy-to-use and visually appealing, involving close collaboration with clients",
    "Agile development methodology for quick and efficient delivery of working software",
    "Commitment to delivering high-quality apps meeting industry standards and best practices",
    "Ongoing support and maintenance to keep apps up-to-date and meeting business needs",
  ];

  return (
    <div>
      <ServicesDetails
        title={title}
        details={details}
        deliverables={deliverables}
        technologies={technologies}
        whyApnito={whyApnito}
      />
      <ContactUsIcon/>
    </div>
  );
};

export default AppDevelopmentService;
