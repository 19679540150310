import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Card, Row, Col, Form, Button, Modal, ListGroup } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import './CareerSupportsDetails1.css';
import NavBars from '../Home/NavBars/NavBars';
import Footer from '../Home/Footer/Footer';
import duration_logo from '../../image/CareerSupports/pngtree-hourglass-vector.png';
import { useLocation } from 'react-router-dom';
import click_icon from '../../image/CareerSupports/click_icon.png';
import dropdown_icon from '../../image/CareerSupports/dropdown_icon1.png';

const CareerSupportsComponent = ({
  title,
  details,
  deliverables,
  technologies,
  whyApnito,
  onProfileSelect,
}) => {
  const [profiles, setProfiles] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [showPopupIndex, setShowPopupIndex] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    message: '',
  });
  const [showProfileModal, setShowProfileModal] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const profileKeys = Object.keys(deliverables);
    setProfiles(profileKeys);

    const params = new URLSearchParams(location.search);
    const profile = params.get('profile');
    if (profile && profileKeys.includes(profile)) {
      setSelectedProfile(profile);
    }
  }, [deliverables, location.search]);

  const profileDeliverables = deliverables;

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const togglePopup = (index) => {
    if (showPopupIndex === index) {
      setShowPopupIndex(null);
    } else {
      setShowPopupIndex(index);
      setShowForm(false);
      setSelectedOption(null);
      setFormData({
        ...formData,
        message: '',
      });
    }
  };

  const handleRadioChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedOption(selectedOption);
    setShowForm(true);
    const selectedDeliverable = deliverables[selectedProfile][showPopupIndex];
    if (selectedDeliverable && selectedDeliverable.duration) {
      const selectedIndex = event.target.dataset.index;
      const message = `Hello APNITO, I am interested in the Service ${selectedDeliverable.title} of ${title} for ${selectedProfile} profile for ${selectedDeliverable.duration[selectedIndex]}. Please book a slot for me.`;
      setFormData({
        ...formData,
        message,
      });
    }
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSending(true);
    const selectedDeliverable = deliverables[selectedProfile][showPopupIndex];
    if (selectedDeliverable && selectedDeliverable.duration && selectedDeliverable.price) {
      const emailMessage = `
      Title: ${selectedDeliverable.title}
      Duration: ${selectedDeliverable.duration[selectedOption]}
      Price: ${selectedDeliverable.price[selectedOption]}
      Name: ${formData.firstName} ${formData.lastName}
      Phone: ${formData.phone}
      Email: ${formData.email}
      Message: ${formData.message}`;
  
      const templateParams = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.phone,
        email: formData.email,
        message: emailMessage,
      };
  
      const emailServices = [
        { serviceId: "service_nii01p5", templateId: "template_y7emb16", userId: "5cqBYCrC0qpiQONyV" },
        { serviceId: "service_nii02p5", templateId: "template_y5632e16", userId: "5cqB0qpiQONyV" }
      ];
  
      const sendEmail = async (index = 0) => {
        if (index >= emailServices.length) {
          alert('Request not submitted due to some technical issue, Please directly reach out to us at contact@apnito.in');
          setIsSending(false);
          return;
        }
  
        const { serviceId, templateId, userId } = emailServices[index];
        try {
          const response = await emailjs.send(serviceId, templateId, templateParams, userId);
          console.log('SUCCESS!', response.status, response.text);
          alert('We successfully received your request. Our team will reach out to you for further process.');
          setShowPopupIndex(null);
          setFormData({ firstName: '', lastName: '', phone: '', email: '', message: '' });
          setShowForm(false);
          setSelectedOption(null);
          setIsSending(false);
        } catch (error) {
          console.log(`FAILED to send email via ${serviceId}`, error);
          sendEmail(index + 1);
        }
      };
  
      sendEmail();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <NavBars />
      <div className="service-detail">
        <br /><br /><br />
        <div className="header">
          <center>
            <h2 className="service-title">
              <span className="header-text">{title}</span>
            </h2>
          </center>
        </div>
        <p className="service-details">{details}</p>
        <Tabs activeKey={activeTab} onSelect={(k) => handleTabClick(k)} id="tabs">
          <Tab eventKey={1} title={<span className='tab-header-1'>Our Deliverables</span>}>
            <div className="dropdown-container">
              <Button
                id="dropdown-basic-button"
                className="custom-dropdown-button"
                onClick={() => setShowProfileModal(true)}
              >
                {selectedProfile || "Select A Profile Option"}
                <img className='dropdown_icon' src={dropdown_icon} alt='dropdown icon'/>
              </Button>
            </div>
            {selectedProfile && profileDeliverables[selectedProfile].map((deliverable, index) => (
              <Card key={index} className="my-3">
                <Card.Body>
                  <Row>
                    <Col>
                      <Card.Title className='title-1' onClick={() => togglePopup(index)}>
                        {deliverable.title} 
                        <img src={click_icon} alt="Click for details" className="clickable-image" />
                      </Card.Title>
                      {showPopupIndex === index && (
                        <div className="popup">
                          <div className="popup-content">
                            <span className="close" onClick={() => togglePopup(index)}>&times;</span>
                            <div className="popup-left">
                              <img className="new-image" src={deliverable.image} alt="Form Image" />
                            </div>
                            <div className="popup-right">
                              <label className='label-1'>SELECT AN OPTION TO PROCEED:</label>
                              {deliverable.duration && deliverable.duration.map((duration, i) => (
                                <div key={i}>
                                  <input
                                    type="radio"
                                    name="priceOption"
                                    value={i}
                                    data-index={i}
                                    checked={selectedOption === i}
                                    onChange={handleRadioChange}
                                  />
                                  <img className="duration_logo" src={duration_logo} alt="Duration Logo" />
                                  <p className='duration'>{duration}</p>
                                  <p className='actual-price'>{deliverable.actualPrice && deliverable.actualPrice[i]}</p>
                                  <h2 className='price'>{deliverable.price && deliverable.price[i]}</h2>
                                  <p className='recommended'>{deliverable.recommended && deliverable.recommended[i]}</p>
                                </div>
                              ))}
                              {showForm && (
                                <Form onSubmit={handleSubmit}>
                                  <div className='form_1'>
                                    <Form.Group controlId="formFirstName" className='form_first_name'>
                                      <Form.Label>FIRST NAME</Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                      />
                                    </Form.Group>
                                    <Form.Group controlId="formLastName" className='form_last_name'>
                                      <Form.Label>LAST NAME</Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        required
                                      />
                                    </Form.Group>
                                  </div>
                                  <div className='form_2'>
                                    <Form.Group controlId="formEmail" className='form_email'>
                                      <Form.Label>EMAIL</Form.Label>
                                      <Form.Control
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                      />
                                    </Form.Group>
                                    <Form.Group controlId="formPhone" className='form_phone'>
                                      <Form.Label>PHONE NUMBER</Form.Label>
                                      <Form.Control
                                        type="tel"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        required
                                      />
                                    </Form.Group>
                                  </div>
                                  <Form.Group controlId="formMessage" className='form_msg'>
                                    <Form.Label>MESSAGE</Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      name="message"
                                      value={formData.message}
                                      onChange={handleChange}
                                      rows={3}
                                    />
                                  </Form.Group>
                                  {isSending ? (
                                    <button disabled>
                                      <i className="fa fa-spinner fa-spin" /> Submitting Request...
                                    </button>
                                  ) : (
                                    <Button variant="primary" type="submit" className='form_submit'>
                                      Request Slot!
                                    </Button>
                                  )}
                                </Form>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Card.Text className='card-content'>{deliverable.content}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </Tab>
          <Tab eventKey={3} title={<span className='tab-header-2'>Why Apnito?</span>}>
            <div className="why-apnito">
              <ul className="why-apnito-list">
                {whyApnito.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>
          </Tab>
        </Tabs>
      </div>
      <br />
      <Footer />

      {/* Profile Selection Modal */}
      <Modal show={showProfileModal} onHide={() => setShowProfileModal(false)} dialogClassName="custom-modal">
        <Modal.Header  className="custom-modal-header" closeButton>
          <Modal.Title>Select a Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          <ListGroup>
            {profiles.map(profile => (
              <ListGroup.Item
                key={profile}
                action
                className='modal-body-profile'
                onClick={() => {
                  setSelectedProfile(profile);
                  onProfileSelect(profile);
                  setShowProfileModal(false);
                }}
              >
                {profile}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CareerSupportsComponent;