import CareerSupportsDetails from '../CareerSupportsDetails';
import ContactUsIcon from '../../Contactus/contacticon';
import Resume_Review from '../../../image/CareerSupports/Resume_Review.png';
import linkedin_reviewer from '../../../image/CareerSupports/LinkedIn_Reviewer.jpeg'

const ProfileReviewer = () => {

  const title = "Profile Reviewer";
  const details = "At Apnito, we are dedicated to enhancing your professional journey through our specialized Profile Reviewer services. We offer services designed to optimize your professional profiles: Resume Reviewer and LinkedIn Reviewer. With Apnito's Profile Reviewer services, you can build a strong, attractive online presence and confidently present a polished, impactful resume, opening doors to new career opportunities.";

  const deliverables = [
    { title: "Resume Reviewer",
      link: "/audio-transcription-details",
      content: "Our resume is often the first impression you make on potential employers. Our Resume Reviewer service ensures that your resume stands out in the pile. We provide detailed, personalized feedback to highlight your strengths, address any gaps, and align your resume with industry standards.", 
      duration:["30 Mins", "60 Mins"],
      price: ["₹ 199", "₹ 299"],
      actualPrice: ["₹ 249", "₹ 399"], 
      recommended: ["", "RECOMMENDED"],
      image: Resume_Review},
    { title: "LinkedIn Reviewer",
      link: "/document-translation-details",
      content: "In today’s digital age, your LinkedIn profile is your professional brand. Our LinkedIn Reviewer service helps you create a powerful online presence that attracts recruiters and networking opportunities. We provide comprehensive reviews and actionable advice to elevate your LinkedIn profile.", 
      duration:["30 Mins", "60 Mins"],
      price:["₹ 149", "₹ 199"],
      actualPrice: ["₹ 199", "₹ 299"], 
      recommended: ["", "RECOMMENDED"],
      image: linkedin_reviewer}
 ];

  const technologies = [
    { src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIJj6T3Pm3wSOAznARupQIb1_H-BZvpdSoxw&usqp=CAU", caption: "Image 1" },
    { src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIJj6T3Pm3wSOAznARupQIb1_H-BZvpdSoxw&usqp=CAU", caption: "Image 2" },
  ];

  const whyApnito = [
    "Benefit from our team of industry professionals with extensive experience",
    "Receive tailored advice designed to meet your specific career goals",
    "Our services are crafted to maximize your job search success and professional growth",
    "We cover every aspect of your profile to ensure it stands out",
    "Gain access to ongoing guidance and resources to keep your profiles updated and competitive"
  ];

  return (
    <div>
      <CareerSupportsDetails
        title={title}
        details={details}
        deliverables={deliverables}
        technologies={technologies}
        whyApnito={whyApnito}
      />
      <ContactUsIcon/>
    </div>
  );
};

export default ProfileReviewer;
