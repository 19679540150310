import React from 'react';
// import './Services.css'; // Create this CSS file to style the list
import { Link } from 'react-router-dom';
import NavBars from '../Home/NavBars/NavBars';
import Footer from '../Home/Footer/Footer';
import ContactUsIcon from '../Contactus/contacticon';

const supports = [
  {
    title: "Profile Reviewer",
    imageSrc: require("../../image/CareerSupports/profile-reviewer.gif"),
    description: "Elevate Your Professional Presence",
    link: "/career-supports/profile-reviewer",
  },
  {
    title: "Mentorship Program",
    imageSrc: require("../../image/CareerSupports/mentorship-program.jpeg"),
    description: "Mentoring Your Success Journey",
    link: "/career-supports/mentorship-program",
  },
  {
    title: "Mock Interview",
    imageSrc: require("../../image/CareerSupports/mock-interview.png"),
    description: "Realistic Interview Experiences",
    link: "/career-supports/mock-interview",
  },
  {
    title: "Career Guidance & Counselling",
    imageSrc: require("../../image/CareerSupports/carrier-guidance-counselling.jpg"),
    description: "Empowering Your Career Choices",
    link: "/career-supports/career-guidance-counselling",
  },
  // {
  //   title: "Training",
  //   imageSrc: require("../../image/CarrierSupports/training.webp"),
  //   description: "Unlocking Your Potential",
  //   link: "/carrier-supports/training",
  // },
//   {
//     title: "Workshop",
//     imageSrc: require("/home/mohan/Work/apnito-website/Frontend/src/image/CarrierSupports/cloud-devops-service.jpeg"),
//     description: "Streamlining your business operations with cloud",
//     link: "/carrier-supports/cloud-devops-service",
//   },
  // Add more service entries as needed
];

// Use the serviceEntries array to generate the cards
const CareerSupportsList = () => {
  return (
    <div>
      <NavBars />
      <br /><br /><br />
      <center><h1 className='service-header'>Our Career Supports</h1></center>
      <br />
      <div className="services">
        {supports.map((support, index) => (
          <div className="service-card" key={index}>
            <img src={support.imageSrc} alt={support.title} />
            <h3 className='service-title-1'>{support.title}</h3>
            <p className='service-description'>{support.description}</p>
            <Link to={support.link}>See Details</Link>
          </div>
        ))}
      </div>
      <br />
      <Footer/>
      <ContactUsIcon/>
    </div>
  );
};

export default CareerSupportsList;
