import React from 'react';
// import Client from '../Client/Client';
// import Feedbacks from '../Feedbacks/Feedbacks';
import About from './About/About';
import Footer from './Footer/Footer';
import NavBars from './NavBars/NavBars';
import Slider from './Slider/Slider';
import ContactForm from '../Contactus/forms/ContactForm';
import ContactUsIcon from '../Contactus/contacticon';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { debounce } from 'lodash';

const Home = () => {

    const location = useLocation();
    useEffect(() => {
        const scrollToSection = debounce(() => {
            if (location.hash === '#about') {
                const aboutSection = document.getElementById('about');
                if (aboutSection) {
                    aboutSection.scrollIntoView({ behavior: 'smooth' });
                }
            } else if (location.hash === '#contact') {
                const contactSection = document.getElementById('contact');
                if (contactSection) {
                    contactSection.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }, 300);

        scrollToSection();
    }, [location.hash]);

    return (
        <div>
            <NavBars/>
            <Slider/>
            {/* <Client/> */}
            <About/>
            {/* <Feedbacks/> */}
            <ContactForm/>
            <Footer/>
            <ContactUsIcon/>
        </div>
    );
};

export default Home;