import React from 'react';
import './JobRole.css';

function JobRole(props) {
  return (
    <div className="JobRole">
      {/* <h2>{props.title}</h2> */}
      <p>{props.summary}</p>
      {/* <a href={props.detailsUrl}>Read Details</a> */}
    </div>
  );
}

export default JobRole;
