import React from 'react';
import './Slider.css';
import ServiceImageSlider from './ServiceImageSlider';
import ClientLogoSlider from './ClientLogoSlider';

const serviceSlides = [
  { 
    image: require('./assets/DataScraping.jpg'), 
    header: 'Data Scraping',
    link: '/services/data-scraping-service'
  },
  { 
    image: require('./assets/WebDevelopment.jpg'), 
    header: 'Web Development',
    link: '/services/web-development-service'
  },
  { 
    image: require('./assets/AppDevelopment.png'), 
    header: 'App Development',
    link: '/services/app-development-service'
  },
  { 
    image: require('./assets/BotDevelopment.jpg'), 
    header: 'Bot Development',
    link: '/services/bot-development-service'
  },
  { 
    image: require('./assets/ProcessAutomation.jpg'), 
    header: 'Process Automation',
    link: '/services/process-automation-service'
  },
  { 
    image: require('./assets/CloudDevOps.jpg'), 
    header: 'Cloud & Devops',
    link: '/services/cloud-devops-service'
  },
  { 
    image: require('./assets/DataManagementAnalytics.jpg'),
    header: 'Data Management & Analytics',
    link: '/services/data-management-analytics-service'
  },
  { 
    image: require('./assets/CustomSoftareDevelopment.jpg'), 
    header: 'Custom Software Development',
    link: '/services/custom-software-development-service'
  },
];

const clientLogos = [
  require('./assets/techtrunk.png'),
  'https://uploads-ssl.webflow.com/64c2dbb9c4026648656ec081/64c2dbb9c4026648656ec0a2_oneshot-logo-white-p-500.webp',
  'https://uploads-ssl.webflow.com/63b7bb07476f22ccbab419d5/63b7bcb4fd7a42fd9af9c6ed_Logo.webp'
];

function Slider() {
  return (
    <div className="slides-body">
      <ServiceImageSlider slides={serviceSlides} />
      <ClientLogoSlider logos={clientLogos} />
    </div>
  );
}

export default Slider;
