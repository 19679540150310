import ServicesDetails from '../ServicesDetails';
import ContactUsIcon from '../../Contactus/contacticon';

const ProcessAutomationService = () => {

  const title = "Process Automation Service";
  const details = "Embark on a journey of operational excellence with our dynamic Process Automation Service. From the foundational efficiency of Basic Automation to the digital transformation prowess of Digital Process Automation, Robotic Process Automation's precision, and the holistic optimization of Business Process Automation, we redefine automation. Elevate your processes further with the intelligence of AI Powered Automation, adapting and learning for seamless, future-proof operations. Your journey to streamlined, agile workflows begins here—where innovation meets efficiency!";

  const deliverables = [
    { title: "Basic Automation", link: "/basic-automation-details", content: "Start your automation journey with Basic Automation. Streamline repetitive tasks, reduce manual effort, and enhance productivity by automating routine processes, laying the foundation for operational efficiency." },
    { title: "Digital Process Automation", link: "/digital-process-automation-details", content: "Transform your workflows with Digital Process Automation. Integrate and digitize end-to-end processes, ensuring a seamless and connected experience. Unleash agility and responsiveness in your operations." },
    { title: "Robotic Process Automation", link: "/robotic-process-automation-details", content: "Empower your workforce with Robotic Process Automation. Deploy bots to mimic human interactions, handling repetitive tasks with precision and speed, freeing up valuable human resources for strategic initiatives." },
    { title: "Business Process Automation", link: "/business-process-automation-details", content: "Optimize your entire business ecosystem with Business Process Automation. Streamline complex processes across departments, fostering collaboration, reducing errors, and accelerating overall business performance." },
    { title: "AI Powered Automation", link: "/ai-powered-automation-details", content: "Step into the future of automation with AI Powered Automation. Harness the intelligence of artificial intelligence to make informed decisions, adapt to dynamic scenarios, and elevate your automation capabilities to new heights." },
    { title: "Performance Testing Automation", link: "/performance-testing-automation-details", content: "Enhance software reliability with our Performance Testing Automation. Utilize advanced tools for streamlined, efficient testing, ensuring your applications deliver optimal performance under various conditions. Elevate the quality of your software seamlessly." },
  ];

  const technologies = [
    { src: "https://example.com/image1.jpg", caption: "Image 1" },
    { src: "https://example.com/image2.jpg", caption: "Image 2" },
    // Add more technologies as needed
  ];

  const whyApnito = [
    "We provide cutting-edge process automation services to help businesses streamline their operations, reduce costs, and boost their profitability",
    "Our team of experienced professionals has the skills and expertise needed to automate a wide range of business processes, from simple and repetitive tasks to complex workflows",
    "By automating your business processes, you can free up your employees' time and resources to focus on higher-value tasks that drive business growth",
    "Our process automation solutions are designed to integrate seamlessly with the existing software & systems, so you can enjoy the benefits of automation without disrupting your business operations",
    "We use the latest technologies and tools to develop custom automation solutions that meet the unique needs of your business and expectations",
    "With our process automation services, you can improve your business efficiency, reduce errors and delays, and achieve faster turnaround times",
  ];

  return (
    <div>
      <ServicesDetails
        title={title}
        details={details}
        deliverables={deliverables}
        technologies={technologies}
        whyApnito={whyApnito}
      />
      <ContactUsIcon/>
    </div>
  );
};

export default ProcessAutomationService;
