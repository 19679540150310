// DataManagementAnalyticsService.js
import ServicesDetails from '../ServicesDetails';
import ContactUsIcon from '../../Contactus/contacticon';

const DataManagementAnalyticsService = () => {

  const title = "Data Management & Analytics Service";
  const details = "Dive into the heart of your data universe with our Data Management & Analytics Service. Unleash the power of insights as we seamlessly navigate through Data Analytics, Engineering, and Business Intelligence. Elevate your data experience with smooth Data Migration, captivating Visualization & Reporting, and personalized Dashboard Development. Let your data tell its story, and together, we'll transform it into a dynamic force driving your business forward. Explore the art and science of data with us!";

  const deliverables = [
    { title: "Data Analytics", link: "/data-analytics-details", content: "Turn raw data into actionable insights with Data Analytics. Our experts utilize advanced analytical techniques to extract meaningful patterns and trends, empowering you to make informed decisions." },
    { title: "Data Engineering", link: "/data-engineering-details", content: "Build a robust data infrastructure with Data Engineering. We design and implement scalable solutions, ensuring the seamless collection, storage, and processing of data for optimal performance." },
    { title: "Business Intelligence", link: "/business-intelligence-details", content: "Drive strategic decisions with Business Intelligence. Our solutions transform complex data into visually compelling reports and dashboards, providing a 360-degree view of your business landscape." },
    { title: "Data Migration", link: "/data-migration-details", content: "Effortlessly transition your data with Data Migration services. Whether upgrading systems or moving to the cloud, we ensure a smooth transfer, minimizing downtime and preserving data integrity." },
    { title: "Data Visualization & Reporting", link: "/data-visualization-reporting-details", content: "Bring your data to life with compelling visualizations and insightful reports. Our Data Visualization & Reporting services make complex information easy to understand, facilitating data-driven decision-making." },
    { title: "Data Visualization Dashboard Development", link: "/dashboard-development-details", content: "Navigate your data landscape effortlessly with customized dashboards. Our experts design intuitive Data Visualization Dashboards that present key metrics and insights, providing a user-friendly interface for data exploration and analysis." },
  ];

  const technologies = [
    { src: "https://example.com/image1.jpg", caption: "Image 1" },
    { src: "https://example.com/image2.jpg", caption: "Image 2" },
    // Add more technologies as needed
  ];

  const whyApnito = [
    "We offer a wide range of data analysis services, from data mining and cleaning to predictive modeling and statistical analysis",
    "Our team of experts will ensure that your data is properly managed and organized, including data cleaning, transformation, and integration",
    "We offer customized report generation services to help you visualize and make sense of your data. Our reports can be tailored to meet your specific needs and requirements",
    "We specialize in creating compelling data visualizations that help you understand your data at a glance. Our visualizations are designed to be easily understood and actionable",
    "Our team has extensive experience in machine learning and AI, which allows us to develop predictive models and algorithms that can help you make data-driven decisions",
    "We take data security very seriously and employ industry-leading security protocols to protect your data from unauthorized access or theft",
    "We offer flexible engagement models to suit your specific needs, including project-based, dedicated team, and managed services",
    // "Our team has worked with clients across a wide range of industries, including healthcare, finance, retail, and more. We bring a deep understanding of industry-specific challenges and opportunities to every project we undertake",
  ];

  return (
    <div>
      <ServicesDetails
        title={title}
        details={details}
        deliverables={deliverables}
        technologies={technologies}
        whyApnito={whyApnito}
      />
      <ContactUsIcon/>
    </div>
  );
};

export default DataManagementAnalyticsService;
