import CareerSupportsDetails from '../CareerSupportsDetails';
import ContactUsIcon from '../../Contactus/contacticon';

const Training = () => {

  const title = "Training";
  const details = "Dive into the wealth of digital information with our Data Scraping Service. Explore the web's vast landscape, extracting valuable insights from websites, files, social media, and emails. Whether it's tracking trends, refining marketing strategies, or unlocking visual insights, we tailor our scraping solutions to meet your unique data needs. Your data journey begins here - where innovation meets interactive exploration. Unleash the power of information with us.";

  const deliverables = [
    { title: "Online Training", 
      link: "/audio-transcription-details", 
      content: "Explore the vast online landscape with Web Scraping. Harvest relevant data from websites to uncover trends, monitor competitors, and gather crucial information that fuels informed decision-making." },
    { title: "Offline Training", 
      link: "/document-translation-details", 
      content: "Effortlessly extract data from various file formats with our File Scraping service. Whether it's PDFs, images, spreadsheets, or documents, we convert raw data into structured insights, ready for analysis." }
 ];

  const technologies = [
    { src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIJj6T3Pm3wSOAznARupQIb1_H-BZvpdSoxw&usqp=CAU", caption: "Image 1" },
    { src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIJj6T3Pm3wSOAznARupQIb1_H-BZvpdSoxw&usqp=CAU", caption: "Image 2" },
  ];

  const whyApnito = [
    "We offer customized data scraping solutions that are tailored to your specific needs, no matter what industry or niche you operate in",
    "Our team can extract data from a wide range of sources, including websites, emails, PDFs, cloud storage, and more",
    "We ensure that the data we scrape is accurate, reliable, and up-to-date",
    "We can also clean and format the data we scrape, so that it's ready for use in your business operations. This can save you time and effort down the line",
    "We offer competitive pricing for our data scraping services, so you can get the data you need without breaking the bank",
    "We offer fast turnaround times for our services, so you can get the data you need when you need it",
    "We ensure that your data is protected at all times. You can trust us to handle your data with care and confidentiality",
  ];

  return (
    <div>
      <CareerSupportsDetails
        title={title}
        details={details}
        deliverables={deliverables}
        technologies={technologies}
        whyApnito={whyApnito}
      />
      <ContactUsIcon/>
    </div>
  );
};

export default Training;
