import React from 'react';
import { CDBFooter, CDBFooterLink, CDBBox, CDBBtn, CDBIcon } from 'cdbreact';
import "./Footer.css"

const Footer = () => {
    return (
        <CDBFooter className="footer">
        <CDBBox display="flex" flex="column" className="mx-auto py-5 text-white" style={{ width: '90%' }}>
          <CDBBox display="flex" justifyContent="between" className="flex-wrap text-white">
            <CDBBox>
              <br></br>
              <br></br>
              <a href="/" className="d-flex align-items-center p-0 text-white">
                <img alt="logo" src={require('../NavBars/assets/image.webp')} width="180px" />
              </a>
              <CDBBox display="flex" className="mt-4">
                <a href="https://www.facebook.com/apnito.in" target="_blank">
                  <CDBBtn flat color="dark">
                    <CDBIcon fab icon="facebook-f" />
                  </CDBBtn>
                </a>
                <a href="https://www.linkedin.com/company/apnito" target="_blank">
                  <CDBBtn flat color="dark" className="mx-3">
                    <CDBIcon fab icon="linkedin" />
                  </CDBBtn>
                </a>
                {/* <a>
                <CDBBtn flat color="dark" className="p-2">
                  <CDBIcon fab icon="instagram" />
                </CDBBtn>
                </a> */}
              </CDBBox>
            </CDBBox>
            <CDBBox>
              <br></br>
              <br></br>
              <p className="header">
                Apnito
              </p>
              <CDBBox flex="column" style={{ cursor: 'pointer', padding: '0' }}>
                <CDBFooterLink  href="/#about"><h5 className='text-white'>About Us</h5></CDBFooterLink>
                <CDBFooterLink  href="/services"><h5 className='text-white'>Services</h5></CDBFooterLink>
                <CDBFooterLink  href="/career-supports"><h5 className='text-white'>Career Supports</h5></CDBFooterLink>
                <CDBFooterLink  href="/careers"><h5 className='text-white'>Careers</h5></CDBFooterLink>
                <CDBFooterLink  href="/#contact"><h5 className='text-white'>Contact Us</h5></CDBFooterLink>
              </CDBBox>
            </CDBBox>
            <CDBBox>
              <br></br>
              <br></br>
              <p className="header" style={{ fontWeight: '600' }}>
                Services
              </p>
                <CDBBox flex="column"  style={{ cursor: 'pointer', padding: '0' }}>
                <CDBFooterLink href="/services/transcription-translation-service"><h5 className='text-white'>Transcription & Translation</h5></CDBFooterLink>
                <CDBFooterLink href="/services/data-scraping-service"><h5 className='text-white'>Data Scraping</h5></CDBFooterLink>
                <CDBFooterLink href="/services/web-development-service"><h5 className='text-white'>Web Development</h5></CDBFooterLink>
                <CDBFooterLink href="/services/app-development-service"><h5 className='text-white'>App Development</h5></CDBFooterLink>
                <CDBFooterLink href="/services/bot-development-service"><h5 className='text-white'>Bot Development</h5></CDBFooterLink>
                <CDBFooterLink href="/services/process-automation-service"><h5 className='text-white'>Process Automation</h5></CDBFooterLink>
                <CDBFooterLink href="/services/cloud-devops-service"><h5 className='text-white'>Cloud & DevOps</h5></CDBFooterLink>
                <CDBFooterLink href="/services/data-management-analytics-service"><h5 className='text-white'>Data Management & Analytics</h5></CDBFooterLink>
                <CDBFooterLink href="/services/custom-software-development-service"><h5 className='text-white'>Custom Software Development</h5></CDBFooterLink>
              </CDBBox>
            </CDBBox>
            <CDBBox>
              <br></br>
              <br></br>
              <p className="header" style={{ fontWeight: '600' }}>
                Career Supports
              </p>
                <CDBBox flex="column"  style={{ cursor: 'pointer', padding: '0' }}>
                <CDBFooterLink href="/career-supports/profile-reviewer"><h5 className='text-white'>Profile Reviewer</h5></CDBFooterLink>
                <CDBFooterLink href="/career-supports/mentorship-program"><h5 className='text-white'>Mentorship Program</h5></CDBFooterLink>
                <CDBFooterLink href="/career-supports/mock-interview"><h5 className='text-white'>Mock Interview</h5></CDBFooterLink>
                <CDBFooterLink href="/career-supports/career-guidance-counselling"><h5 className='text-white'>Career Guidance & Counselling</h5></CDBFooterLink>
                {/* <CDBFooterLink href="/career-supports/training"><h5 className='text-white'>Training</h5></CDBFooterLink> */}
                {/* <CDBFooterLink href="/career-supports/workshop"><h5 className='text-white'>Workshop</h5></CDBFooterLink> */}
              </CDBBox>
            </CDBBox>
          </CDBBox>
          <br/><br/>
          <small className="footer-desclaimer" >&copy; Apnito, 2022. All rights reserved.</small>
        </CDBBox>
      </CDBFooter>
    );
};

export default Footer;