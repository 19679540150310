import React from 'react';
import './Services.css'; // Create this CSS file to style the list
import { Link } from 'react-router-dom';
import NavBars from '../Home/NavBars/NavBars';
import Footer from '../Home/Footer/Footer';
import ContactUsIcon from '../Contactus/contacticon';

const services = [
  {
    title: "Transcription & Translation",
    imageSrc: require("../../image/Services/transcription-ranslation-service.webp"),
    description: "Bridging Language Gaps",
    link: "/services/transcription-translation-service",
  },
  {
    title: "Data Scraping",
    imageSrc: require("../../image/Services/data-scraping-service.jpg"),
    description: "Extracting Valuable Insights",
    link: "/services/data-scraping-service",
  },
  {
    title: "Web Development",
    imageSrc: require("../../image/Services//web-development-service.webp"),
    description: "Building Your Online Presence",
    link: "/services/web-development-service",
  },
  {
    title: "App Development",
    imageSrc: require("../../image/Services/app-development-service.webp"),
    description: "Transforming Ideas into Apps",
    link: "/services/app-development-service",
  },
  {
    title: "Bot Development",
    imageSrc: require("../../image/Services/bot-development-service.png"),
    description: "Bots That Work for You",
    link: "/services/bot-development-service",
  },
  {
    title: "Process Automation",
    imageSrc: require("../../image/Services/process-automation-service.jpg"),
    description: "Streamlining Business Processes",
    link: "/services/process-automation-service",
  },
  {
    title: "Cloud & DevOps",
    imageSrc: require("../../image/Services/cloud-devops-service.jpeg"),
    description: "Scalable Cloud Infrastructure",
    link: "/services/cloud-devops-service",
  },
  {
    title: "Data Management & Analytics",
    imageSrc: require("../../image/Services/data-management-analytics-service.jpg"),
    description: "Unlocking The Power of Your Data",
    link: "/services/data-management-analytics-service",
  },
  {
    title: "Custom Software Development",
    imageSrc: require("../../image/Services/custom-software-developemnt-service.png"),
    description: "Tailored Software Solutions",
    link: "/services/custom-software-development-service",
  },
  // Add more service entries as needed
];

// Use the serviceEntries array to generate the cards
const ServiceList = () => {
  return (
    <div>
      <NavBars />
      <br /><br /><br />
      <center>
        <h1 className='service-header'>Our Services</h1>
      </center>
      <br />
      <div className="services">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <img src={service.imageSrc} alt={service.title} />
            <h3 className='service-title-1'>{service.title}</h3>
            <p className='service-description'>{service.description}</p>
            <Link to={service.link}>See Details</Link>
          </div>
        ))}
      </div>
      <br />
      <Footer/>
      <ContactUsIcon/>
    </div>
  );
};

export default ServiceList;
