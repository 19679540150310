import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Slider.css';

const ServiceImageSlider = ({ slides }) => {
  const handleButtonClick = (url) => {
    // Redirect to the desired page when the button is clicked
    window.location.href = url;
  };

  const customArrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: '40%',
    width: '4%',
    height: '20%',
    cursor: 'pointer',
    transition: 'opacity 0.6s ease',
    background: 'rgba(255, 255, 255, 0.3)', // Set the button color
  };

  const customArrowPrev = (clickHandler, hasPrev, label) =>
    hasPrev && (
      <button type="button" onClick={clickHandler} title={label} style={{ ...customArrowStyles, left: '0' }}>
        {/* You can use your own arrow icon here or adjust the font size */}
        <div style={{ fontSize: '24px', color: 'white' }}>{'<'}</div>
      </button>
    );

  const customArrowNext = (clickHandler, hasNext, label) =>
    hasNext && (
      <button type="button" onClick={clickHandler} title={label} style={{ ...customArrowStyles, right: '0' }}>
        {/* You can use your own arrow icon here or adjust the font size */}
        <div style={{ fontSize: '24px', color: 'white' }}>{'>'}</div>
      </button>
    );

  return (
    <div className="service-slider">
      <Carousel
        autoPlay
        infiniteLoop
        showStatus={false}
        showThumbs={false}
        interval={3000}
        dynamicHeight
        stopOnHover={false}
        // renderArrowPrev={customArrowPrev} // Custom Design
        // renderArrowNext={customArrowNext} // Custom Design
      >
        {slides.map((slide, index) => (
          <div key={index} className="slider-item">
            <img 
              src={slide.image} 
              alt={slide.header} 
              className="service-image"
            />
            <div className="legend-container">
              <button className="slider-button" onClick={() => handleButtonClick(slide.link)}>
                Click Me to explore the Service
              </button>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ServiceImageSlider;
